:root {
  --font-gilroy: "Gilroy", sans-serif;
  --trans-in: all 0.2s ease-in;
  --trans-out: all 0.2s ease-out;
  --accent-black: #404142;
  --accent-red: #C1272D;
  --accent-blue: #2AA0D6;
  --accent-darker-blue: #0C2835;
  --accent-grey: #E3E3E4;
  --accent-darker-grey: #B2C3CB;
  --accent-darker-yellow: #FAC05E;
  --accent-white: #FFFFFF;
  --accent-darker-white: #EEEEEE;
  --default-radius: 1rem;
  --content-width: 90rem;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: var(--font-gilroy);
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  outline: none;
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.color-red {
  color: var(--accent-red);
}

.overflow-hidden {
  overflow: hidden;
}

/* Header */

header {
  width: 100%;
  display: flex;
  position: fixed;
  height: 5rem;
  background-color: var(--accent-white);
  box-shadow: 0 8px 4px rgba(164, 173, 176, 0.1);
  z-index: 10;
}

.flag-ls,
.flag-rs {
  width: 1.25rem;
  height: 5rem;
}

.flag-top,
.flag-bottom {
  width: 100%;
  height: 50%;
}

.flag-top {
  background-color: var(--accent-blue);
}

.flag-bottom {
  background-color: var(--accent-darker-yellow);
}

.header-logo {
  padding-left: 3.75rem;
  display: flex;
  align-items: center;
}

.logo svg {
  width: 6.5rem;
  height: 2.5rem;
}

.menu {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.menu-item {
  color: var(--accent-black);
  font-weight: 700;
  font-size: 1.25rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
  transition: var(--trans-out);
}

.menu-item:hover {
  color: var(--accent-blue);
  transition: var(--trans-in);
}

.menu-item:last-of-type {
  margin-right: 0;
}

.hotline {
  display: flex;
  align-items: center;
}

.hotline-label {
  color: var(--accent-black);
  font-weight: 400;
  font-size: 1.25rem;
  padding-right: 1.5rem;
}

.hotline-phone {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--accent-black);
  transition: var(--trans-out);
  padding-right: 2.4375rem;
}

.hotline-phone:hover {
  color: var(--accent-blue);
  transition: var(--trans-in);
}

.header-hamburger-container {
  display: none;
}

.header-hamburger {
  width: 3.75rem;
  height: 2.8125rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.header-hamburger span {
  display: block;
  position: absolute;
  height: .2rem;
  width: 100%;
  background: var(--accent-black);
  border-radius: var(--default-radius);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.header-hamburger span:nth-child(1) {
  top: 0px;
}

.header-hamburger span:nth-child(2) {
  top: 1.125rem;
}

.header-hamburger span:nth-child(3) {
  top: 2.25rem;
}

.header-hamburger.open span:nth-child(1) {
  top: 1.125rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.header-hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -3.75px;
}

.header-hamburger.open span:nth-child(3) {
  top: 1.125rem;
  background-color: var(--accent-black);
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.mobile-header-container {
  display: none;
}

/* Preview block */
.preview {
  margin-top: 5rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-height: 41.25rem;
}

.preview-bg {
  height: 41.25rem;
}

.preview-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.preview-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, .8);
}

.preview-content {
  display: flex;
  flex-direction: column;
  width: var(--content-width);
  margin: 0 auto;
  padding: 4.75rem 0 0 15rem;
}

.preview-title {
  color: var(--accent-black);
  font-size: 5rem;
  font-weight: 600;
  margin: 0 0 2.5rem;
  letter-spacing: -2px;
}

.preview-description {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--accent-black);
  line-height: 1.67;
  margin-bottom: 2rem;
  max-width: 43.125rem;
}

.preview-btn {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--accent-black);
  background: var(--accent-darker-yellow);
  max-width: 15.3125rem;
  box-shadow: 8px 8px 0 #D99F3D;
  padding: 1.5rem;
  border-radius: var(--default-radius);
  transition: var(--trans-out);
}

.preview-btn:hover {
  box-shadow: 0 0 0 #D99F3D;
  transition: var(--trans-in);
}

/* Requirements block */
.requirements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 0 0;
}

.requirements-title {
  font-weight: 700;
  font-size: 3.5rem;
  color: var(--accent-black);
  text-align: center;
  margin: 0 0 2rem;
}

.requirements-slider {
  width: 100%;
  margin-bottom: 3rem;
}

.requirements-slider__image {
  padding: 0 .25rem;
  width: 20.25rem;
  height: 19.625rem;
  position: relative;
  margin: 0;
  overflow: hidden;
}

.requirements-slider__label {
  position: absolute;
  padding: 0 0 1.25rem 1.5rem;
  top: 0;
  left: .25rem;
  right: .25rem;
  bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--accent-white);
  display: flex;
  align-items: flex-end;
  border-radius: var(--default-radius);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .3));
}

.requirements-slider__image img {
  width: 100%;
  height: 100%;
  border-radius: var(--default-radius);
}

.requirements-controls,
.reports-controls {
  display: flex;
}

.requirements-controls__line,
.reports-controls__line {
  display: flex;
  align-items: center;
  position: relative;
}

.requirements-controls__line #point-line {
  position: absolute;
  border-radius: var(--default-radius);
  height: .25rem;
  width: calc(100% / 8);
  background-color: var(--accent-black);
}

.requirements-controls__line #line,
.reports-controls__line #reports-line {
  width: 25rem;
  height: .25rem;
  display: flex;
  background-color: var(--accent-grey);
  border-radius: var(--default-radius);
}

.requirements-controls__arrow,
.reports-controls__arrow {
  color: var(--accent-black);
  cursor: pointer;
  transition: var(--trans-out);
}

.requirements-controls__arrow.left,
.reports-controls__arrow.left {
  margin-right: 8.75rem;
}

.requirements-controls__arrow.right,
.reports-controls__arrow.right {
  margin-left: 8.75rem;
}

.requirements-controls__arrow:hover,
.reports-controls__arrow:hover {
  color: var(--accent-blue);
  transition: var(--trans-in);
}

.requirements-controls__arrow svg,
.reports-controls__arrow svg {
  width: 1.4375rem;
  height: 2.75rem;
}

/* About block */
.about {
  display: flex;
  padding: 7.5rem 0;
  justify-content: center;
}

.about-container {
  width: 54.625rem;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-weight: 700;
  font-size: 3.5rem;
  color: var(--accent-black);
  text-align: center;
  margin: 0 0 2rem;
}

.about-description {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2;
  color: var(--accent-black);
  margin-bottom: 2.5rem;
}

.about-people {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-person {
  color: var(--accent-black);
  width: 12rem;
  margin-bottom: 2rem;
}

.person-photo {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.person-photo img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.person-name {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.person-position {
  font-weight: 400;
  font-size: 1rem;
}

/* How work block */
.how-work {
  padding: 7.375rem 0 8.125rem;
  background: var(--accent-darker-blue);
  width: 100%;
  display: flex;
  justify-content: center;
}

.how-work-container {
  width: 43.75rem;
}

.how-work-title {
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  margin: 0 0 2.375rem;
  color: var(--accent-white);
}

.how-work-steps {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.how-work-step {
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
}

.how-work-step:after {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  border-radius: var(--default-radius);
  width: 100%;
  background: rgba(250, 192, 94, .5);
}

.how-work-step__ls {
  width: 50%;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--accent-blue);
}

.how-work-step__rs {
  width: 45%;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--accent-white);
}

.how-work-step:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
}

.how-work-step:last-of-type:after {
  background: none;
}

/* Donate */
.donate {
  padding: 7.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-title {
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  margin: 0 0 2rem;
  color: var(--accent-black);
}

.donate-container {
  width: var(--content-width);
  display: flex;
  padding: 0 9.9375rem 0 3.75rem;
}

.donate-col {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.donate-col-head {
  margin-bottom: 2rem;
}

.donate-col-head,
.donate-col-body {
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  color: var(--accent-black);
  line-height: 1.65;
}

.donate-col__title {
  font-weight: 700;
  font-size: 1.5rem;
}

.donate-col__email {
  text-decoration: underline;
  transition: var(--trans-out);
}

.donate-col__phone {
  transition: var(--trans-out);
}

.donate-col__phone:hover,
.donate-col__email:hover {
  color: var(--accent-blue);
  transition: var(--trans-out);
}

/* Needs block */
.needs {
  padding: 7.5rem 0;
  background-color: var(--accent-darker-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.needs-title {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--accent-black);
  margin: 0 0 2rem;
}

.needs-container {
  width: var(--content-width);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 3.75rem;
}

.needs-card {
  width: calc(33% - 1rem);
  padding: 2.5rem 1.875rem 2rem;
  background-color: var(--accent-white);
  border-radius: var(--default-radius);
  margin-bottom: 2rem;
  text-align: center;
  color: var(--accent-black);
}

.needs-card:nth-last-child(-n+3) {
  margin-bottom: 0;
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  color: var(--accent-grey);
}

.card-icon .icon-dna svg {
  height: 5.625rem;
  width: 3.875rem;
}

.card-icon .icon-communication svg {
  height: 5.125rem;
  width: 3rem;
}

.card-icon .icon-ammunition svg {
  height: 4.8125rem;
  width: 5.625rem;
}

.card-icon .icon-means svg {
  height: 4.5rem;
  width: 7rem;
}

.card-icon .icon-fuel svg {
  height: 4.75rem;
  width: 3.25rem;
}

.card-icon .icon-equipment svg {
  height: 5rem;
  width: 4.125rem;
}

.card-label {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.card-description {
  font-size: 1rem;
  font-weight: 400;
}

/* Reports block */
.reports {
  padding: 7.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports-title {
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  margin: 0 0 2rem;
  color: var(--accent-black);
}

.reports-slider {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  height: 25rem;
}

.reports-slider-container {
  width: 31.5rem !important;
  height: 29rem;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.75rem 1.875rem 0;
  position: relative;
  transition: var(--trans-in);
}

.reports-slider__img {
  height: 17.5rem;
  transform: scale(1);
  transition: var(--trans-in);
}

.reports-slider__description {
  top: 22rem;
  left: 5%;
  text-align: center;
  width: 90%;
  font-weight: 400;
  position: absolute;
  font-size: 1.25rem;
  color: var(--accent-white);
}

.slick-active .reports-slider__description {
  color: var(--accent-black);
}

.slick-active .reports-slider-container {
  padding: 1.75rem 3.75rem 0;
}

.slick-active .reports-slider__img {
  transform: scale(1.2);
  transition: var(--trans-out);
}

.reports-slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--default-radius);
}

.reports-controls__line #reports-point-line {
  position: absolute;
  border-radius: var(--default-radius);
  height: .25rem;
  background-color: var(--accent-black);
}

/* Partners block */

.partners {
  background-color: var(--accent-darker-white);
  padding: 7.5rem 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}

.partners-title {
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0 0 3.125rem;
  color: var(--accent-black);
}

.partners-container {
  width: 54.5rem;
  display: flex;
  justify-content: space-between;
}

.partners-lazar svg {
  width: 12.9375rem;
  height: 5rem;
}

.partners-safe-sky {
  width: 12.625rem;
  height: 5rem;
}

.partners-safe-sky img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.partners-kshdu svg {
  width: 8.375rem;
  height: 6rem;
}

/* Footer */
footer {
  padding: 7.5rem 0 2.375rem;
  color: var(--accent-black);
}

.footer-title {
  text-align: center;
  margin: 0 0 1.875rem;
  font-size: 3.5rem;
  font-weight: 700;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.footer-schedule,
.footer-hotline,
.footer-messengers,
.footer-email {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
}

.footer-hotline a {
  transition: var(--trans-out);
}

.footer-hotline a:hover {
  color: var(--accent-blue);
  transition: var(--trans-in);
}

.footer-social-media {
  font-size: 1.5rem;
  font-weight: 400;
}

.footer-email a,
.footer-social-media a {
  color: var(--accent-blue);
  transition: var(--trans-out);
}

.footer-email a:hover,
.footer-social-media a:hover {
  color: var(--accent-darker-blue);
  transition: var(--trans-in);
}

.sub-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.sub-footer-link {
  margin-right: 1.5rem;
}

.sub-footer-link svg {
  width: 6.5rem;
  height: 2.5rem;
}

.sub-footer-flag svg {
  width: 4rem;
  height: 2.5rem;
}

.sub-footer-year {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

@media screen and (max-width: 1440px) {
  * {
    --content-width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .needs-card:nth-last-child(-n+3) {
    margin-bottom: 2rem;
  }

  .needs-card {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 992px) {
  .menu {
    padding: 0 2rem;
  }

  .preview-content {
    padding: 4.75rem 0 0 7.5rem;
  }

  .needs-card {
    width: calc(50% - 1rem);
  }
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  header {
    align-items: center;
  }

  .header-logo {
    padding-left: 2.5rem;
  }

  .header-hamburger-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 2.5rem;
  }

  .mobile-header-container {
    display: flex;
    background-color: var(--accent-white);
    top: -65rem;
    height: calc(100% - 5rem);
    position: fixed;
    overflow: hidden;
    z-index: 9;
    width: 100%;
    flex-direction: column;
    transition: var(--trans-in);
  }

  .mobile-header-container.open {
    top: 5rem;
    transition: var(--trans-out);
  }

  .mobile-header-menu {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .mobile-header-menu__item {
    color: var(--accent-darker-blue);
    font-size: 2.5rem;
    text-align: center;
    padding: 2.5rem 0 1.5rem;
    font-weight: 700;
  }

  .mobile-header-hotline {
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 3.75rem;
  }

  .mobile-header-hotline .hotline-label,
  .mobile-header-hotline .hotline-phone {
    font-size: 2rem;
  }

  .preview {
    max-height: initial;
  }

  .preview-content {
    padding: 4.75rem 0 4.75rem 3.75rem;
  }

  .preview-title {
    font-size: 3.75rem;
  }

  .requirements-title,
  .about-title,
  .how-work-title,
  .donate-title,
  .needs-title,
  .reports-title,
  .partners-title,
  .footer-title {
    font-size: 2.75rem;
  }

  .requirements-controls__line #line,
  .reports-controls__line #reports-line {
    width: 20rem;
  }

  .requirements-controls__arrow.left,
  .reports-controls__arrow.left {
    margin-right: 5.75rem;
  }

  .requirements-controls__arrow.right,
  .reports-controls__arrow.right {
    margin-left: 5.75rem;
  }

  .about-description {
    font-size: 1.25rem;
  }

  .about-people {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }



  .person-name {
    font-size: 1.25rem;
  }

  .how-work-step__ls {
    font-size: 1.25rem;
  }

  .how-work-step__rs {
    font-size: 1.05rem;
  }

  .donate-container {
    padding: 0 3.75rem;
  }

  .donate-col:nth-child(2) {
    padding-left: 1.25rem;
  }

  .needs-card {
    width: calc(50% - .75rem);
    padding: 1.5rem 1.875rem 1rem;
  }

  .needs-card:nth-last-child(-n+3) {
    margin-bottom: 1.5rem;
  }

  .needs-card {
    margin-bottom: 1.5rem;
  }

  .card-label {
    font-size: 1.25rem;
  }

  .requirements {
    padding: 4.25rem 0 0;
  }

  .about,
  .how-work,
  .donate,
  .needs,
  .reports,
  .partners {
    padding: 4.25rem 0;
  }

  .reports-slider__description {
    top: 20rem
  }

  .reports-slider {
    height: 26rem;
  }

  .reports-slider-container {
    height: 26rem;
  }

  .reports-slider__img {
    height: 15.5rem;
  }

  .partners-container,
  .about-container,
  .how-work-container {
    width: 100%;
    padding: 0 3.75rem;
  }

  .partners-lazar svg,
  .partners-safe-sky {
    width: 10rem;
    height: 4rem;
  }

  .partners-kshdu svg {
    width: 6.375rem;
    height: 4rem;
  }

  footer {
    padding: 4.25rem 0 2.375rem;
  }

  .footer-schedule,
  .footer-hotline,
  .footer-messengers,
  .footer-email,
  .footer-social-media {
    font-size: 1.25rem;
  }
}


@media screen and (max-width: 576px) {

  header,
  .flag-ls,
  .flag-rs {
    height: 4rem;
  }

  .header-logo {
    padding-left: 1.5rem;
  }

  .header-hamburger-container {
    padding-right: 1.5rem;
  }

  .header-hamburger {
    width: 2.75rem;
    height: 2.125rem;
  }

  .header-hamburger span:nth-child(2),
  .header-hamburger.open span:nth-child(1),
  .header-hamburger.open span:nth-child(3) {
    top: .8rem;
  }

  .header-hamburger span:nth-child(3) {
    top: 1.625rem;
  }

  .header-hamburger.open span:nth-child(1) {
    top: .8rem;
  }

  .preview-bg {
    height: 40.25rem;
  }

  .preview-description {
    width: 100%;
  }

  .preview-btn {
    max-width: 100%;
    text-align: center;
    box-shadow: 0 8px 0 #d99f3d;
  }

  .preview {
    margin-top: 4rem;
  }

  .preview-title {
    font-size: 3.25rem;
  }

  .preview-content {
    padding: 4.75rem 2.75rem;
  }

  .mobile-header-container {
    height: calc(100% - 4rem);
  }

  .mobile-header-container.open {
    top: 4rem;
    transition: var(--trans-out);
  }

  .mobile-header-hotline .hotline-label,
  .mobile-header-hotline .hotline-phone {
    font-size: 1.5rem;
  }

  .requirements-controls__arrow.left,
  .reports-controls__arrow.left {
    margin-right: 2.5rem;
  }

  .requirements-controls__arrow.right,
  .reports-controls__arrow.right {
    margin-left: 2.5rem;
  }

  .requirements-controls__line #line,
  .reports-controls__line #reports-line {
    width: 15rem;
  }

  .requirements-controls__arrow svg,
  .reports-controls__arrow svg {
    width: 1rem;
    height: 2rem;
  }

  .partners-container,
  .about-container,
  .how-work-container,
  .donate-container,
  .needs-container {
    padding: 0 2.75rem;
  }

  .about-people {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .person-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .about-person {
    width: 12rem;
  }

  .person-name,
  .person-position {
    text-align: center;
  }

  .person-name {
    font-size: 1.5rem;
  }

  .donate-container {
    flex-direction: column;
  }

  .donate-col {
    width: 100%;
  }

  .donate-col:nth-child(1) {
    margin-bottom: 2rem;
  }

  .donate-col:nth-child(2) {
    padding-left: 0;
  }


  .needs-card {
    width: 100%;
  }

  .partners-lazar svg,
  .partners-safe-sky {
    width: 8rem;
    height: auto;
  }

  .partners-kshdu svg {
    width: 4.375rem;
    height: auto;
  }

  footer {
    padding: 4.25rem 2.75rem 2.375rem;
  }

  .footer-social-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-social-media span {
    text-align: center;
    margin-bottom: 1.6rem;
  }
}

@media screen and (max-width: 480px) {
  .preview-title {
    font-size: 2.75rem;
  }

  .preview-description {
    font-size: 1.25rem;
  }

  .hotline-phone,
  .hotline-label {
    padding-right: 0;
  }

  .hotline-label {
    margin-bottom: 1.25rem;
  }

  .mobile-header-hotline {
    flex-direction: column;
    align-items: center;
  }

  .reports-slider__description {
    left: 1rem;
    right: 1rem;
  }

  .reports-slider {
    height: 19rem;
  }

  .slick-active .reports-slider-container {
    padding: 1.25rem 2.25rem 0;
  }

  .reports-slider-container {
    width: 20rem !important;
    height: 22rem;
    padding: 0 .5rem;
  }

  .reports-slider__img {
    height: 11.5rem;
  }

  .reports-slider__description {
    top: 15rem;
  }
}

@media screen and (max-width: 425px) {
  .how-work-step {
    flex-direction: column;
  }

  .how-work-step__ls {
    margin-bottom: 1.25rem;
  }

  .how-work-step__ls,
  .how-work-step__rs {
    width: 100%;
  }

  .partners-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .partners-lazar svg,
  .partners-safe-sky {
    width: 12rem;
    margin: .5rem;
  }

  .partners-kshdu svg {
    width: 8.375rem;
    height: 6rem;
    margin: 2rem 1rem;
  }

  .footer-social-media a {
    word-break: break-word;
    text-align: start;
  }

  .footer-social-media {
    align-items: flex-start;
  }

  .footer-social-media span {
    margin-bottom: 0;
  }

  .footer-schedule,
  .footer-hotline,
  .footer-messengers,
  .footer-email {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .preview-title {
    font-size: 2.25rem;
  }

  .preview-content {
    padding: 4.75rem 1.25rem;
  }

  .preview-bg {
    height: 34.25rem;
  }

  .partners-container,
  .about-container,
  .how-work-container,
  .donate-container,
  .needs-container {
    padding: 0 1.25rem;
  }

  footer {
    padding: 4.25rem 1.25rem 2.375rem;
  }

  .footer-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 320px) {

  .requirements-controls__line #line,
  .reports-controls__line #reports-line {
    width: 13rem;
  }
}
